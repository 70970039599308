import React from "react";

interface BoardProps extends React.HTMLAttributes<HTMLDivElement> {}

const Board: React.FC<BoardProps> = (props) => {
  return (
    <div className="board grid grid-cols-3 grid-rows-3 gap-4 " {...props} />
  );
};

export default Board;
