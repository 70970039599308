import { Link } from "react-router-dom";
import win from "../assets/winconfetti.png";
import lose from "../assets/lost-removebg-preview.png";
import neutral from "../assets/icons8-neutral-face-96.png";
import "../Styles/PopUp.css";

const { REACT_APP_CONTACT_URL } = process.env;

interface ModalProps {
  score: any;
  draw: boolean;
}

export default function Modal({ score, draw }: ModalProps) {
  return (
    <div className="popup">
      <div className="modal  ">
        <div className="overlay"></div>
        <div className="modal-content flex flex-col items-center justify-center gap-y-4 w-72 md:w-80 h-auto p-8  border-4 border-black">
          {draw && (
            <img src={neutral} className="h-20 lg:h-28" alt="draw"></img>
          )}
          {draw ? (
            <h1 className="text-2xl lg:text-3xl font-semibold"> Match Draw</h1>
          ) : (
            <div className="flex flex-col items-center justify-center gap-y-2">
              <div>
                {score ? (
                  <img className=" h-20 lg:h-28" alt="win" src={win}></img>
                ) : (
                  <img className=" h-20 lg:h-28" alt="lose" src={lose}></img>
                )}
              </div>
              {score ? (
                <h2 className=" text-2xl lg:text-3xl font-semibold">
                  Congratulation!
                </h2>
              ) : (
                <h2 className=" text-2xl lg:text-3xl font-semibold">Opps !</h2>
              )}
              {score ? (
                <p className=" text-xl lg:text-2xl font-semibold">You won</p>
              ) : (
                <p className=" text-xl lg:text-2xl font-semibold">You lost</p>
              )}
            </div>
          )}
          <Link to={"/"}>
            {" "}
            <button className="button bg-yellow-100 rounded-full w-40 p-3 font-semibold">
              OK
            </button>
          </Link>
          <a
            href={REACT_APP_CONTACT_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="button  bg-yellow-100 rounded-full uppercase w-40 p-3 font-semibold">
              {" "}
              Contact Us
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}
