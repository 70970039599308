import { Link } from "react-router-dom";
import logo from "../assets/workplay-logo-with-shadow.png";

const { REACT_APP_LOGO_URL } = process.env;

interface HomeProps {}

const Home: React.FC<HomeProps> = ({}) => {
  return (
    <div className="element flex flex-col   text-black items-center py-10 gap-y-8 px-10">
      <div>
        <a href={REACT_APP_LOGO_URL} target="_blank" rel="noopener noreferrer">
          <img src={logo} className="h-10 cursor-pointer" alt="not found" />
        </a>
      </div>
      <h1 className=" text-2xl font-semibold lg:text-3xl lg:font-extrabold">
        Welcome to Tic Tac Toe Game
      </h1>
      <p className="text-xl font-extrabold lg:text-2xl  w-auto lg:w-96 lg:font-semibold ">
        {" "}
        Play Tic Tac Toe Against the Computer! You'll be 'X', and the Computer
        will be 'O'. Best of luck!{" "}
      </p>
      <Link to={"game"}>
        {" "}
        <button className="button bg-yellow-100 rounded-full w-40 p-3 text-xl  font-extrabold lg:font-semibold">
          START
        </button>
      </Link>
    </div>
  );
};
export default Home;
