import React from "react";

interface SquareProps {
  x: boolean;
  o: boolean;
  isWinnerSquare: any;
  onClick: () => void;
}

const Square: React.FC<SquareProps> = ({ x, o, isWinnerSquare, onClick }) => {
  const squareStyle = isWinnerSquare ? { backgroundColor: "#375335" } : {};
  return (
    <div
      className="square cursor-pointer rounded-lg w-20 h-20 md:w-36 lg:w-40  md:h-36 lg:h-40 text-3xl md:text-6xl lg:text-8xl text-black text-center pt-5 md:pt-8 lg:pt-6   bg-yellow-100"
      onClick={onClick}
      style={squareStyle}
    >
      {x ? "X" : o ? "O" : ""}
    </div>
  );
};

export default Square;
