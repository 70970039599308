import React, { useState, useEffect } from "react";
import Board from "./Board";
import Square from "./Square";
import PopUp from "../Components/PopUp";
import logo from "../assets/workplay-logo-with-shadow.png";
import computer from "../assets/icons8-computer-80.png";
import user from "../assets/icons8-user-80.png";

const { REACT_APP_LOGO_URL } = process.env;
const defaultSquares = () => new Array(9).fill(null);
const lines: number[][] = [
  [0, 1, 2],
  [3, 4, 5],
  [6, 7, 8],
  [0, 3, 6],
  [1, 4, 7],
  [2, 5, 8],
  [0, 4, 8],
  [2, 4, 6],
];

const Game: React.FC = () => {
  const [squares, setSquares] = useState<string[]>(defaultSquares());
  const [winner, setWinner] = useState<string | null>(null);
  const [draw, setDraw] = useState(false);
  const [turn, setTurn] = useState(
    "Click on any square to make your first move"
  );
  const [disable, setDisable] = useState(false);
  const [image, setImage] = useState<string | null>(null);

  const isComputerTurn =
    squares.filter((square) => square !== null).length % 2 === 1;

  const linesThatAre = (a: any, b: any, c: any): number[][] => {
    return lines.filter((squareIndexes) => {
      const squareValues = squareIndexes.map((index) => squares[index]);
      return (
        JSON.stringify([a, b, c].sort()) === JSON.stringify(squareValues.sort())
      );
    });
  };

  const emptyIndexes = squares
    .map((square, index) => (square === null ? index : null))
    .filter((val) => val !== null);
  const playerWon = linesThatAre("x", "x", "x").length > 0;
  const computerWon = linesThatAre("o", "o", "o").length > 0;
  const putComputerAt = (index: any): void => {
    let newSquares = [...squares];
    setTimeout(() => {
      if (!playerWon) {
        newSquares[index] = "o";
        setSquares(newSquares);
        setImage(user);
        setTurn("It's your turn");
      }
    }, 1000);
  };

  const isWinningLine = (a: number, b: number, c: number): boolean => {
    const [valA, valB, valC] = [squares[a], squares[b], squares[c]];
    return valA !== null && valA === valB && valA === valC;
  };

  const getWinningLine = (): number[] | null => {
    for (const line of lines) {
      const [a, b, c] = line;
      if (isWinningLine(a, b, c)) {
        return line;
      }
    }
    return null;
  };

  useEffect(() => {
    if (!draw) {
      if (playerWon && !draw) {
        setTurn("Player won");
        setImage(user);
        setDisable(true);
        setTimeout(() => {
          setWinner("x");
        }, 1500);
      } else if (computerWon && !draw) {
        setTurn("Computer won");
        setImage(computer);
        setDisable(true);
        setTimeout(() => {
          setWinner("o");
        }, 1500);
      }
    }
    if (emptyIndexes.length <= 0) {
      setImage(null);
      setDraw(true);
      setTurn("Draw");
    }

    if (isComputerTurn) {
      const winingLines = linesThatAre("o", "o", null);

      if (winingLines.length > 0) {
        const winIndex = winingLines[0].filter(
          (index) => squares[index] === null
        )[0];
        putComputerAt(winIndex);
        return;
      }

      const linesToBlock = linesThatAre("x", "x", null);
      if (linesToBlock.length > 0) {
        const blockIndex = linesToBlock[0].filter(
          (index) => squares[index] === null
        )[0];
        putComputerAt(blockIndex);
        return;
      }

      const linesToContinue = linesThatAre("o", null, null);
      if (linesToContinue.length > 0) {
        putComputerAt(
          linesToContinue[0].filter((index) => squares[index] === null)[0]
        );
        return;
      }

      const randomIndex =
        emptyIndexes[Math.ceil(Math.random() * emptyIndexes.length)];
      putComputerAt(randomIndex);
    }
  }, [squares, draw]);

  useEffect(() => {
    if (playerWon || computerWon || draw) {
      setDisable(true);
    }
  }, [playerWon, computerWon, draw]);

  const handleSquareClick = (index: number): void => {
    const isPlayerTurn =
      squares.filter((square) => square !== null).length % 2 === 0;

    if (isPlayerTurn && squares[index] === null && !disable) {
      if (emptyIndexes.length >= 3) {
        setTurn("It's Computer Turn");
        setImage(computer);
      }
      let newSquares = [...squares];
      newSquares[index] = "x";
      setSquares(newSquares);
    }
  };
  const winningLine = getWinningLine();
  return (
    <div className="element flex flex-col py-10 gap-y-8 items-center  ">
      <div>
        <a href={REACT_APP_LOGO_URL} target="_blank" rel="noopener noreferrer">
          <img src={logo} className="h-10 cursor-pointer" alt="not found" />
        </a>
      </div>
      <div className="px-10 text-xl flex flex-row h-10 font-semibold lg:text-2xl">
        {image && (
          <img
            src={image}
            className=" mr-2 mt-1 lg:mt-0 lg:mr-2 h-5 lg:h-7"
            alt="image"
          />
        )}
        <h1>{turn}</h1>
      </div>
      <Board>
        {squares.map((square, index) => {
          const isWinnerSquare = winningLine && winningLine.includes(index);
          return (
            <Square
              key={index}
              x={square === "x"}
              o={square === "o"}
              onClick={() => handleSquareClick(index)}
              isWinnerSquare={isWinnerSquare}
            />
          );
        })}
      </Board>
      {!!winner && winner === "x" && (
        <div className="result green">
          <PopUp score={true} draw={draw}></PopUp>
        </div>
      )}
      {!!winner && winner === "o" && (
        <div className="result red">
          <PopUp score={false} draw={draw}></PopUp>
        </div>
      )}
      {winner === null && draw && (
        <div className="result red">
          <PopUp score={null} draw={draw}></PopUp>
        </div>
      )}
    </div>
  );
};

export default Game;
